<template>
  <section class="invoice-add-wrapper" v-if="showData">
    <b-row class="invoice-add">

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="12"
        xl="12"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <b-row>
            <!-- Button: Send Invoice -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mb-75"
                block
                @click="saveData"
                :disabled="saveButtonStatus"
                variant="primary"
              >
                <b-spinner small v-if="saveButtonStatus" />
                <div v-if="!saveButtonStatus">Guardar orden</div>
                <div v-if="saveButtonStatus">Guardando orden...</div>
              </b-button>
            </b-col>
            <!-- Button: Download -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mb-75"
                block
                @click="printInvoice"
                disabled
              >
                Imprimir
              </b-button>
            </b-col>
            <!-- Button: Print -->
            <b-col md="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                block
                disabled
              >
                Enviar por correo
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Helios
                    </h3>
                  </div>
                    <h3 class="">
                      Acero "Javier"
                    </h3>
                  <b-card-text class="mb-25">
                    Calzada del Valle #149
                  </b-card-text>
                  <b-card-text class="mb-25">
                    San Pedro Garza Garcia, NL 66274, MX
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +52 (811) 456 7891, +52 (551) 543 2198
                  </b-card-text>
                </div>

                <vue-apex-charts
                  v-if="false"
                  height="200"
                  :options="earningsChart.chartOptions"
                  :series="series"
                />

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <!-- <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Orden
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div> -->
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Fecha de creacion:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.creationDateString"
                      class="form-control invoice-edit-input"
                    />
                    <!-- TODO ajustar el configTime -->
                      <!-- :config="configTime" -->
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Fecha de entrega estimada:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.estimatedDateString"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Fecha de entregado:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.deliveryDateString"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client Details -->
            <b-card-body
              class="invoice-padding pt-0"
            >
              <b-row class="invoice-spacing">

                <!-- Col: Invoice To -->
                <b-col
                  cols="12"
                  xl="6"
                  class="mb-lg-1"
                >
                  <h6 class="mb-2">
                    Cliente:
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    :options="companies"
                    label="companyName"
                    input-id="invoice-data-client"
                    :clearable="false"
                    :value="this.companyData.companyName"
                    @input='setClientData($event, true)'
                  />
                </b-col>

                <!-- Col: Client Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                  style="justify-content: center!important;"
                >
                <!-- Selected Client -->
                  <div
                    v-if="companyData"
                    class="mt-1"
                  >
                    <h6 class="mb-25">
                      {{ companyData.companyName }}
                    </h6>
                    <b-card-text class="mb-25">
                      {{ companyData.address }} {{ companyData.country }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ companyData.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      {{ companyData.companyEmail }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Item Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="form"
                class="repeater-form"
                :style="{height: 10}"
              >
                <b-row
                  v-for="(item, indexItem) in invoiceData.items"
                  :key="indexItem"
                  ref="row"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="4"
                        >
                          Item
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cantidad estimada
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Cantidad actual
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Precio
                        </b-col>
                        <b-col
                          cols="12"
                          lg="2"
                        >
                          Subtotal
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <!-- Item ROW -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">
                        <!-- METAL -->
                        <b-col cols="12" lg="4">
                          <label class="d-inline d-lg-none">Item</label>
                          <v-select
                            :options="items"
                            label="itemName"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Selecciona un item"
                            :reduce="itemName => itemName.idItem"
                            :value='invoiceData.items[indexItem].idItem'
                            @input='getProvidersByItems($event, indexItem)'
                          />
                        </b-col>
                        <!-- Cantidad estimada-->
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Cantidad</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            @input="updateInput(indexItem, null, $event, 'itemQuantity')"
                            type="number"
                            class="mb-2"
                            v-model="item.quantity"
                          />
                        </b-col>
                        <!-- Cantidad actual-->
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Cantidad</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            :value="calculatedValues(indexItem, null, 'totalQuantityItem')"
                            type="number"
                            class="mb-2"
                            readonly
                          />
                        </b-col>
                        <!-- Precio -->
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Precio</label>
                          <b-form-input
                            @keypress="restrictNumber($event)"
                            @input="updateInput(indexItem, null, $event, 'itemPrice')"
                            type="number"
                            class="mb-2"
                            v-model="item.price"
                          />
                        </b-col>
                        <!-- Subtotal -->
                        <b-col cols="12" lg="2">
                          <label class="d-inline d-lg-none">Subtotal</label>
                           <b-form-input
                            class="mb-2"
                            readonly
                            :value="calculatedValues(indexItem, null, 'itemSubTotal')"
                          />
                        </b-col>
                        <!-- Linea -->
                        <b-col cols="12">
                          <hr style="height:1px;border-width:0;color:gray;background-color:gray">
                        </b-col>
                        <!-- PROVEEDORES -->
                        <b-col>
                          <div>
                            <div>
                              <b-form
                                ref="form"
                                class="repeater-form"
                              >

                                <!-- Row Loop -->
                                <b-row
                                  v-for="(provider, indexProvider) in invoiceData.items[indexItem].providers"
                                  :id="provider.idProvider"
                                  :key="indexProvider"
                                  ref="row"
                                >
                                  <!-- Proveedor -->
                                  <b-col md="4">
                                    <b-form-group
                                      label="Proveedor"
                                      label-for="item-name"
                                    >
                                      <v-select
                                        :options="providers"
                                        label="companyName"
                                        input-id="invoice-data-client"
                                        :clearable="false"
                                        placeholder="Selecciona un proveedor"
                                        :reduce="companyName => companyName.idCompany"
                                        :value='invoiceData.items[indexItem].providers[indexProvider].idProvider'
                                        @input='invoiceData.items[indexItem].providers[indexProvider].idProvider = $event'
                                      />
                                    </b-form-group>
                                  </b-col>

                                  <!-- Cantidad estimada -->
                                  <b-col md="2">
                                    <b-form-group
                                      label="Cantidad estimada"
                                      label-for="cost"
                                    >
                                      <b-form-input
                                        @keypress="restrictNumber($event)"
                                        @input="updateInput(indexItem, indexProvider, $event, 'estimatedQuantity')"
                                        v-model="provider.estimatedQuantity"
                                        type="number"
                                        placeholder="32"
                                      />
                                    </b-form-group>
                                  </b-col>

                                  <!-- ETA -->
                                  <b-col md="3">
                                    <b-form-group
                                      label="Fecha estimada de entrega"
                                      label-for="quantity"
                                    >
                                    <flat-pickr
                                      class="form-control"
                                      v-model="provider.estimatedDateString"
                                    />
                                    </b-form-group>
                                  </b-col>

                                  <!-- Precio -->
                                  <b-col md="2">
                                    <b-form-group
                                      label="Precio"
                                      label-for="quantity"
                                    >
                                      <b-form-input
                                        @keypress="restrictNumber($event)"
                                        @input="updateInput(indexItem, indexProvider, $event, 'price')"
                                        v-model="provider.price"
                                        type="number"
                                        placeholder="$1.23"
                                      />
                                    </b-form-group>
                                  </b-col>

                                  <!-- White Space -->
                                  <b-col md="4">
                                  </b-col>

                                  <!-- Cantidad entregada -->
                                  <b-col md="2">
                                    <b-form-group
                                      label="Cantidad entregada"
                                      label-for="cost"
                                    >
                                      <b-form-input
                                        @keypress="restrictNumber($event)"
                                        @input="updateInput(indexItem, indexProvider, $event, 'deliveryQuantity')"
                                        v-model="provider.deliveryQuantity"
                                        type="number"
                                        placeholder="32"
                                      />
                                    </b-form-group>
                                  </b-col>

                                  <!-- ETA -->
                                  <b-col md="3">
                                    <b-form-group
                                      label="Fecha de entregado"
                                      label-for="quantity"
                                    >
                                       <flat-pickr
                                        class="form-control"
                                        v-model="provider.deliveryDateString"

                                      />
                                    </b-form-group>
                                  </b-col>

                                  <!-- Total -->
                                  <b-col md="2">
                                    <b-form-group
                                      label="Total"
                                      label-for="price"
                                    >
                                      <b-form-input
                                        readonly
                                        :value="calculatedValues(indexItem, indexProvider, 'providerSubTotal')"
                                      />
                                    </b-form-group>
                                  </b-col>

                                  <b-col md="10">
                                 
                                  </b-col>
                                  
                                  <b-col md="2">
                                    <b-button
                                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                      variant="outline-danger"
                                      class="mt-0 mt-md-2"
                                      @click="removeItem(indexItem, indexProvider, 'proveedor')"
                                    >
                                      <feather-icon
                                        icon="XIcon"
                                        class="mr-25"
                                      />
                                      <span>Eliminar</span>
                                    </b-button>
                                  </b-col>

                                  <b-col cols="12">
                                    <hr>
                                  </b-col>
                                </b-row>

                              </b-form>
                            </div>
                            <div style="text-align: center;">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="primary"
                                @click="addNewItem(indexItem, 'proveedor')"
                              >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-25"
                              />
                              <span>Agregar proveedor</span>
                            </b-button>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(indexItem, null, 'item')"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItem(null, 'item')"
              >
                Agregar item
              </b-button>
            </b-card-body>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing">

                <!-- Col: Note -->
                <b-col
                  cols="12"
                  md="8"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                >
                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">Nota: </span>
                  <b-form-textarea v-model="invoiceData.note" />
                </b-card-body>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-6 d-flex justify-content-end"
                >
                  <div class="invoice-total-wrapper" style="max-width: 13rem">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'subTotal')}}
                      </p>
                    </div>
                    <!-- <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">
                        $28
                      </p>
                    </div> -->
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        IVA (16%):
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'iva')}}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        $ {{calculatedValues(null, null, 'total')}}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      items: [],
      companies: [],
      providers: [],
      companyData: {},
      // Templates
      itemTemplate: {
        idItem: null,
        quantity: null,
        price: null,
        idMeasurementUnit: 1,
        subTotal: null,
        providers: [
          {
            idProvider: null,
            estimatedQuantity: null,
            deliveryQuantity: null,
            estimatedDate: null,
            deliveryDate: null,
            price: null,
            discount: 0,
            subTotal: null,
          }
        ]
      },
      providerTemplate: {
        idProvider: null,
        estimatedQuantity: null,
        deliveryQuantity: null,
        estimatedDate: null,
        deliveryDate: null,
        price: null,
        subTotal: null,
      },
      // Invoice
      invoiceData: {
        idOrder: null,
        creationDate: null,
        estimatedDate: null,
        deliveryDate: null,
        creationDateString: new Date(),
        estimatedDateString: null,
        deliveryDateString: null,
        idCompany: null,
        total: null,
        discount: 0,
        tax: null,
        idCountryTax: 1,
        subTotal: null,
        idStatus: 1,
        note: 'La forma de pego del Cliente 1 sera en tranferencia bancaria.',
        items: [
          JSON.parse(JSON.stringify(
          {
            idItem: null,
            quantity: null,
            price: null,
            idMeasurementUnit: 1,
            subTotal: null,
            providers: [
              {
                idProvider: null,
                estimatedQuantity: null,
                deliveryQuantity: null,
                estimatedDate: null,
                deliveryDate: null,
                estimatedDateString: null,
                deliveryDateString: null,
                price: null,
                discount: 0,
                subTotal: null,
              }
            ]
          }))
        ]
      },
      //Props
      saveButtonStatus: false,
      showData: false,
      // 
      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only          
      },

      // TODO
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Entregado', 'Sin Asignar', 'Asignado'],
          stroke: { width: 0 },
          colors: ['#00a377', '#990000', "#fdbe02"],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Entregado',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      series: [53, 16, 31],
      }
  },
  created() {
    this.getClients();
  },
  beforeRouteLeave (to, from , next) { // Evitar que se salgan de la pantalla y perder datos
    const answer = window.confirm('Do you really want to leave?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  methods: {
    getOrder(){
      this.$axios.get(this.$services + 'orders/get_order', {
        params: {
          brokerID: '4',
          // operation: "4",
          idOrder: router.currentRoute.params.id,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data);
          // Set Data
          this.invoiceData = res.data.data
          // Set time
          this.invoiceData.creationDateString = new Date(this.invoiceData.creationDate);
          this.invoiceData.estimatedDateString = new Date(this.invoiceData.estimatedDate);
          this.invoiceData.deliveryDateString = new Date(this.invoiceData.deliveryDate);
          // Items
          this.invoiceData.items.forEach((element, indexItem) => {
            // Providers by Items
            this.invoiceData.items[indexItem].providers.forEach((element, indexProvider) => {
              this.invoiceData.items[indexItem].providers[indexProvider].deliveryDateString = new Date(this.invoiceData.items[indexItem].providers[indexProvider].deliveryDate);
              this.invoiceData.items[indexItem].providers[indexProvider].estimatedDateString = new Date(this.invoiceData.items[indexItem].providers[indexProvider].estimatedDate);
            });
          });
          // Set selectedCompany data
          let companyData = this.companies.filter(company => company.idCompany == this.invoiceData.idCompany);
          this.setClientData(companyData[0], false);
          this.showData = true;
          // console.log(this.invoiceData)
          this.getProvidersByItems(this.invoiceData.items[0].idItem, 0);
          // this.getProvidersByItems(this.invoiceData.items[1].idItem, 1);
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getClients() {
      this.$axios.get(this.$services + 'clients/get_clients', {
        params: {
          idBroker: '4',
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.companies = res.data.data;
          this.getOrder();
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getItemsByClient(idCompany) {
      this.$axios.get(this.$services + 'clients/get_metals_clients', {
        params: {
          idBroker: '4',
          idCompany: idCompany,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        this.items = [];
        if (!res.data.error) {
          this.items = res.data.data;
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    getProvidersByItems(idItem, index) {
      this.invoiceData.items[index].idItem = idItem;
      this.$axios.get(this.$services + 'providers/get_providers_metal', {
        params: {
          idBroker: '4',
          idItem: idItem,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.providers = res.data.data;
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    setClientData(value, changeClient) {
      this.companyData = value;
      this.getItemsByClient(value.idCompany);
      this.invoiceData.idCompany = value.idCompany;

      if (changeClient){
        this.invoiceData.items = [];
        this.addNewItem(null, 'item'); 
      }
    },
    setProvider(idProvider, indexItem, indexProvider) {
      console.log(idProvider, indexItem, indexProvider)
      return
      this.invoiceData.items[indexItem].providers[indexProvider].idProvider = $event
    },
    saveData() {
      // Date format
      this.invoiceData.creationDate = this.$moment(this.invoiceData.creationDateString).valueOf();
      this.invoiceData.estimatedDate = this.$moment(this.invoiceData.estimatedDateString).valueOf();
      this.invoiceData.deliveryDate = this.$moment(this.invoiceData.deliveryDateString).valueOf();
      // Items
      this.invoiceData.items.forEach((element, indexItem) => {
        // Providers by Items
        this.invoiceData.items[indexItem].providers.forEach((element, indexProvider) => {
          this.invoiceData.items[indexItem].providers[indexProvider].deliveryDate = this.$moment(element.deliveryDateString).valueOf();
          this.invoiceData.items[indexItem].providers[indexProvider].estimatedDate = this.$moment(element.estimatedDateString).valueOf();
        });
      });

      this.saveButtonStatus = true;
      this.updateOrder();

    },
    updateOrder(){
      this.$axios.post(this.$services+'orders/update_order', {
        brokerID: "4",
        invoiceData: this.invoiceData,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', res.data.data);
          this.saveButtonStatus = false;
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    updateInput(indexItem, indexProvider, value, type){ // Inputs de precios y cantidades

      let inputValue = parseFloat(value); // Parse al dato del input

      if (isNaN(inputValue)) { // Input es NaN
        inputValue = 0;
      }

      // CLIENTE
      if (type === 'itemQuantity') {
        this.invoiceData.items[indexItem].quantity = inputValue;
      } else if (type === 'itemPrice') {
        this.invoiceData.items[indexItem].price = inputValue;
      } 
      // PROVEEDOR
      else if (type === 'estimatedQuantity') {
        this.invoiceData.items[indexItem].providers[indexProvider].estimatedQuantity = inputValue;
      } else if (type === 'price') {
        this.invoiceData.items[indexItem].providers[indexProvider].price = inputValue;
      } else if (type === 'deliveryQuantity') {
        this.invoiceData.items[indexItem].providers[indexProvider].deliveryQuantity = inputValue;
      }
    },
    calculatedValues(indexItem, indexProvider, type){ // Calcula el subtotal
      let value = 0;
      if (type === 'itemSubTotal') {
        value = this.invoiceData.items[indexItem].price * this.invoiceData.items[indexItem].quantity;
        value = parseFloat(value.toFixed(2));
        this.invoiceData.items[indexItem].subTotal = value;
        return "$ " + utils.numberWithCommas(value);
      } else if (type === 'providerSubTotal') {
        value = this.invoiceData.items[indexItem].providers[indexProvider].price * this.invoiceData.items[indexItem].providers[indexProvider].deliveryQuantity;
        value = parseFloat(value.toFixed(2));
        this.invoiceData.items[indexItem].providers[indexProvider].subTotal = value;
        return "$ " + utils.numberWithCommas(value);
      } else if (type === 'totalQuantityItem') {
        this.invoiceData.items[indexItem].providers.forEach(element => {
          value += element.deliveryQuantity;
        });
        return value;
      } else if (type === 'subTotal') {
        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });
        value = parseFloat(value.toFixed(2));
        this.invoiceData.subTotal = value;
        return utils.numberWithCommas(value);
      } else if (type === 'iva') {
        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });
        value *= 0.16;
        value = parseFloat(value.toFixed(2));
        this.invoiceData.tax = value;
        return utils.numberWithCommas(value);
      } else if (type === 'total') {
        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });
        value *= 1.16;
        value = parseFloat(value.toFixed(2));
        this.invoiceData.total = value;
        return utils.numberWithCommas(value);
      } else {
        return null;
      }    
    },
    addNewItem(indexItem, type) {
      if (type === 'item') {
        this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemTemplate)));
      } else if (type === 'proveedor') {
        this.invoiceData.items[indexItem].providers.push(JSON.parse(JSON.stringify(this.providerTemplate)));
      } 
    },
    removeItem(indexItem, indexProvider, type) {
      if (type === 'item') {
        this.invoiceData.items.splice(indexItem, 1);
      } else if (type === 'proveedor') {
        this.invoiceData.items[indexItem].providers.splice(indexProvider, 1);
      } 
    },
    showToast(variant, data) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋  Orden guardada con exito!',
          variant,
        },
      })
    },
    restrictNumber(event){ // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
      }
    },
    // TODO TERMINAR
    printInvoice() {
      window.print()
    },
  },
  computed: {
  },
}
</script>
